<div class="popup-backdrop" [class.show]="showPopup">
  <div class="popup-wrapper px-2 py-5 px-md-5">
    <h4 class="fw-bold text-accent">¿QUÉ ES LEGALIS?</h4>
    <div class="divider divider-dark my-4"></div>
    <p class="pt-2 px-4">
      Legalis es una plataforma que facilita el acceso a la amplia y dispersa legislación cubana, mediante funcionalidades que permiten encontrar, consultar y descargar normas publicadas en la Gaceta de Cuba desde el año 2009 hasta la actualidad.
      <br><br>
      Si quieres recibir el listado de normas publicadas en la Gaceta cada semana y otros contenidos generados por nuestros expertos, ¡suscríbete al boletín semanal!
    </p>
    <div class="w-100 d-flex flex-column flex-lg-row align-items-center justify-content-evenly mt-4">
      <button class="btn btn-outline-accent" (click)="showPopup=false">NO GRACIAS</button>
      <button class="btn btn-accent mt-4 mt-lg-0" (click)="onSubscribe()">SUSCRIBIRME</button>
    </div>
  </div>
</div>
