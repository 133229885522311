<div class="gazette-item d-flex flex-column">
    <div class="gazette-header py-2 px-3 d-flex">
        <div class="d-flex flex-column text-start flex-grow-1">
            <h5 class="text-blue fw-bold mb-0">
                Gaceta Oficial No. {{ item.number }}
            </h5>
            <div class="d-flex align-items-center">
                <small class="text-small text-uppercase me-2"
                    >{{ item.date | date: "dd/MM/YYYY" }}</small
                >
                <small class="text-small text-uppercase">{{ item.type }}</small>
            </div>
        </div>
        <div class="d-flex align-items-end">
            <small
                class="text-small text-blue me-4 pointer"
                (click)="showGazettePdf(item?.file)"
                ><i class="fas fa-arrow-up-right-from-square me-1"></i>
                ABRIR</small
            >
            <small
                class="text-small text-blue pointer"
                fileSaver
                [fileName]="item?.file"
                [http]="downloadGazettePdf(item?.id, item?.file)"
                ><i class="fas fa-download me-1"></i> DESCARGAR</small
            >
        </div>
    </div>
    <div
        class="gazette-content p-3 flex-grow-1"
        [(ngbCollapse)]="contentCollapsed"
    >
        <ng-scrollbar track="vertical">
            <div
                *ngFor="let n of item.normatives; let i = index"
                class="d-flex flex-column"
            >
                <small class="text-small"
                    ><a
                        class="text-blue fw-bold"
                        [routerLink]="['/normativa', n.slug]"
                        >{{ n.name }}</a
                    ></small
                >
                <small class="truncate text-small mt-1">{{ n.summary }}</small>
                <div class="divider"></div>
            </div>
        </ng-scrollbar>
    </div>
</div>
