<div class="viewer-container modal-body">
  <app-loader *ngIf="!isReady"></app-loader>
  <ng2-pdfjs-viewer
    #pdfViewer
    [viewerId]="'pdfViewer'"
    (onDocumentLoad)="isReady = true"
    [viewBookmark]="false"
    [fullScreen]="false"
    [openFile]="false"
    [print]="false"
  ></ng2-pdfjs-viewer>
</div>
<div class="modal-footer">
  <div class="ms-auto">
    <!-- <button class="btn btn-sm bg-surface text-blue fw-bold me-2" fileSaver [fileName]="filename" [url]="fileUrl">
      <i class="fas fa-download me-3"></i>
    </button> -->
    <button class="btn btn-sm fw-bold btn-light" (click)="close()">Aceptar</button>
  </div>
</div>
