<header class="header-wrapper" id="top">
    <div class="header-container container">
        <div class="row m-0 h-100">
            <div
                class="col-lg-3 d-none d-lg-flex justify-content-start align-items-start"
            >
                <div
                    class="d-flex align-items-center justify-content-center pe-5 pt-5 header-social"
                >
                    <a
                        href="https://www.facebook.com/groups/legaliscuba"
                        target="blank"
                        class="btn btn-outline-light btn-sm border-2 me-3"
                    >
                        <i class="fab fa-facebook"></i>
                    </a>
                    <a
                        href="https://twitter.com/eltoquejuridico"
                        target="blank"
                        class="btn btn-outline-light btn-sm border-2 me-3"
                    >
                        <i class="fab fa-twitter"></i>
                    </a>
                    <a
                        href="https://t.me/+7j46ojvcK5xjM2Jh"
                        target="blank"
                        class="btn btn-outline-light btn-sm border-2"
                    >
                        <i class="fab fa-telegram"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-6 col px-lg-0 p-4 align-self-start">
                <div
                    class="container d-flex flex-column align-items-center justify-content-center h-100 p-0 pl-2"
                >
                    <a routerLink="/"
                        ><img
                            alt="Logo"
                            class="img-fluid header-logo"
                            src="/assets/legalis-logo.png"
                    /></a>
                    <small class="header-slogan my-2"
                        >Acceso fácil a la legislación cubana</small
                    >
                    <div class="input-group input-group-lg mt-4 header-search">
                        <input
                            class="form-control"
                            type="search"
                            (keyup.enter)="onSearchClicked()"
                            [(ngModel)]="query"
                            placeholder="Buscar..."
                        />
                        <button
                            class="btn btn-outline-primary"
                            (click)="onSearchClicked()"
                        >
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-3 d-none d-lg-flex justify-content-end align-self-start">
        <nav class="header-nav ps-5 pt-5" role="navigation">
          <a routerLink="/gacetas" routerLinkActive="active">ÍNDICE DE GACETAS</a>
          <a routerLink="/consultas" routerLinkActive="active">PREGUNTAS JURÍDICAS FRECUENTES</a>
          <a routerLink="/acerca-de" routerLinkActive="active">¿QUIÉNES SOMOS?</a>
        </nav>
      </div> -->
        </div>
    </div>
    <nav class="header-tabs d-none d-lg-flex" role="navigation">
        <div class="tab">
            <a routerLink="/inicio" routerLinkActive="active">INICIO</a>
        </div>
        <div class="tab">
            <a routerLink="/busqueda-avanzada" routerLinkActive="active"
                >BÚSQUEDA AVANZADA</a
            >
        </div>

        <div class="tab">
            <a
                routerLink="/glosario"
                [queryParams]="{ startswith: 'A' }"
                routerLinkActive="active"
                >GLOSARIO</a
            >
        </div>

        <div class="tab">
            <a routerLink="/gacetas" routerLinkActive="active"
                >ÍNDICE DE GACETAS</a
            >
        </div>

        <div class="tab">
            <a routerLink="/consultas" routerLinkActive="active"
                >PREGUNTAS JURÍDICAS FRECUENTES</a
            >
        </div>
    </nav>
    <nav class="header-tabs d-flex flex-column d-lg-none" role="navigation">
        <div class="header-tabs-toggler" (click)="collapse.toggle()">
            <small class="text-uppercase text-accent fw-bold mb-1"
                >Otras opciones de búsqueda</small
            >
            <i
                class="fas text-accent"
                [class.fa-chevron-down]="collapseFilterNav"
                [class.fa-chevron-up]="!collapseFilterNav"
            ></i>
        </div>
        <div
            #collapse="ngbCollapse"
            class="w-100"
            [(ngbCollapse)]="collapseFilterNav"
        >
            <div class="tab">
                <a routerLink="/busqueda-avanzada" routerLinkActive="active"
                    >BÚSQUEDA AVANZADA</a
                >
            </div>
            <div class="tab">
                <a
                    routerLink="/glosario"
                    [queryParams]="{ startswith: 'A' }"
                    routerLinkActive="active"
                    >GLOSARIO</a
                >
            </div>
            <div class="tab">
                <a routerLink="/gacetas" routerLinkActive="active"
                    >ÍNDICE DE GACETAS</a
                >
            </div>
            <div class="tab">
                <a routerLink="/consultas" routerLinkActive="active"
                    >PREGUNTAS JURÍDICAS FRECUENTES</a
                >
            </div>
            <div class="tab">
                <a routerLink="/inicio" routerLinkActive="active">INICIO</a>
            </div>
            <!-- <div class="tab">
        <a routerLink="/inicio" routerLinkActive="active">INICIO</a>
      </div> -->
        </div>
    </nav>
    <div class="header-menu" ngbDropdown>
        <button class="dropdown-toggle" ngbDropdownToggle>
            <i class="fas fa-ellipsis-v text-primary fa-lg"></i>
        </button>
        <div class="dropdown-menu" ngbDropdownMenu>
            <a routerLink="/inicio" routerLinkActive="active" ngbDropdownItem
                >INICIO</a
            >
            <a
                routerLink="/busqueda-avanzada"
                routerLinkActive="active"
                ngbDropdownItem
                >BÚSQUEDA AVANZADA</a
            >
            <a
                routerLink="/glosario"
                [queryParams]="{ startswith: 'A' }"
                routerLinkActive="active"
                ngbDropdownItem
                >GLOSARIO</a
            >
            <a routerLink="/gacetas" routerLinkActive="active" ngbDropdownItem
                >ÍNDICE DE GACETAS</a
            >
            <a routerLink="/consultas" routerLinkActive="active" ngbDropdownItem
                >PREGUNTAS JURÍDICAS FRECUENTES</a
            >
            <!-- <a routerLink="/acerca-de" routerLinkActive="active" ngbDropdownItem>¿QUIÉNES SOMOS?</a> -->
        </div>
    </div>
</header>
