<div class="filter-panel bg-surface d-flex flex-column px-4 w-100">
  <a class="btn btn-link w-100 d-flex d-md-none align-items-center flex-column p-2" (click)="collapse.toggle()">
    <span class="fw-bold text-small text-dark text-center p-2">FILTROS DE BÚSQUEDA</span>
    <i class="fas fa-chevron-down text-accent"></i>
  </a>
  <a class="btn btn-link w-100 d-none d-md-flex align-items-center flex-column p-2">
    <span class="fw-bold text-small text-dark text-center p-2">FILTROS DE BÚSQUEDA</span>
  </a>
  <div #collapse="ngbCollapse" [ngbCollapse]="showCollapsed" class="pb-2">
    <form #form="ngForm" class="row">
      <div class="divider m-0 col-12"></div>
      <div class="col-12 text-start" *ngIf="showState">
        <label class="fw-bold form-group-label text-small mt-4">ESTADO DE LA NORMA</label>
        <div class="form-check text-small">
          <input
            class="form-check-input"
            type="radio"
            id="state_default"
            name="state"
            [value]="null"
            [(ngModel)]="params.state"
            (ngModelChange)="paramsChange$.next()"
          />
          <label class="form-check-label" for="state_default"> Todos los estados </label>
        </div>
        <div class="form-check text-small" *ngFor="let item of states; let i = index">
          <input
            class="form-check-input"
            type="radio"
            id="state_{{ i }}"
            name="state"
            [value]="item.state"
            [(ngModel)]="params.state"
            (ngModelChange)="paramsChange$.next()"
          />
          <label class="form-check-label" for="state_{{ i }}">
            {{ item.state }} <small class="ms-2 text-accent">{{ item.count }}</small>
          </label>
        </div>
      </div>
      <div class="col-12 text-start" *ngIf="showGazetteType">
        <label class="fw-bold form-group-label text-small mt-4">TIPOS DE EDICIÓN</label>
        <div class="form-check text-small">
          <input
            class="form-check-input"
            id="type_default"
            type="radio"
            name="type"
            [(ngModel)]="params.type"
            (ngModelChange)="paramsChange$.next()"
            [value]="null"
          />
          <label class="form-check-label" for="type_default"> Todas la ediciones </label>
        </div>
        <div class="form-check text-small" *ngFor="let item of gazetteTypes.slice(0, -1); let i = index">
          <input
            class="form-check-input"
            id="type_{{ i }}"
            type="radio"
            [value]="item.type"
            name="type"
            [(ngModel)]="params.type"
            (ngModelChange)="paramsChange$.next()"
          />
          <label class="form-check-label" for="type_{{ i }}">
            {{ item.type }} <small class="ms-2 text-accent">{{ item.count }}</small>
          </label>
        </div>
      </div>
      <ng-container *ngIf="showOrganism">
        <div class="divider"></div>
        <div class="col-12 col-12 text-start">
          <label class="fw-bold form-group-label text-small">EMISOR</label>
          <ng-select
            name="organism"
            [(ngModel)]="params.organism"
            (ngModelChange)="paramsChange$.next()"
            placeholder="Todos los organismos"
          >
            <ng-option *ngFor="let o of organisms" [value]="o">{{ o }}</ng-option>
          </ng-select>
        </div>
      </ng-container>
      <div class="divider" *ngIf="showYear"></div>
      <div class="col-12 text-start" *ngIf="showYear">
        <label class="fw-bold form-group-label text-small">AÑO DE PUBLICACIÓN</label>
        <div class="row">
          <div class="col-12">
            <label for="" class="form-control-label">Seleccione el año</label>
            <ng-select
              name="year"
              [ngModel]="params.year"
              (ngModelChange)="onYearChanged($event)"
              placeholder="Seleccionar"
              [clearable]="allowUnsetYear"
            >
              <ng-option *ngFor="let y of _years" [value]="y">{{ y }}</ng-option>
            </ng-select>
          </div>
          <div class="col-12 mt-1">
            <label class="form-control-label mt-2">Seleccione el período</label>
            <div class="d-flex align-items-start me-2">
              <ngx-slider
              name="year_range"
              [value]="params.year_gte || this.slideYearSelected"
              [highValue]="params.year_lte || this.slideMaxYear"
              (highValueChange)="onSliderHighValueChange($event)"
              (valueChange)="onSliderValueChange($event)"
              [options]="sliderOpts$"
            ></ngx-slider>
            </div>
          </div>
        </div>
      </div>
      <div class="divider" *ngIf="showThematics"></div>
      <div class="col-12 text-start" *ngIf="showThematics">
        <label class="fw-bold form-group-label text-small">ETIQUETAS</label>
        <!--<ng-select
            name="tematica"
            [(ngModel)]="params.tematica"
            (ngModelChange)="paramsChange$.next()"
            bindLabel="name"
            bindValue="name"
            placeholder="Todas las temáticas"
          >
            <ng-option *ngFor="let item of thematics" [value]="item">
              {{ item.name }} <small class="ms-2 text-accent">{{ item.count }}</small>
            </ng-option>
          </ng-select>-->
        <div class="form-check text-small">
          <input
            class="form-check-input"
            type="radio"
            id="thematic_default"
            [value]="null"
            name="tematica"
            [(ngModel)]="params.tematica"
            (ngModelChange)="paramsChange$.next()"
          />
          <label class="form-check-label" for="thematic_default"> Todas las etiquetas </label>
        </div>
        <div class="form-check text-small" *ngFor="let item of topThematics; let i = index">
          <input
            class="form-check-input"
            type="radio"
            id="thematic_{{ i }}"
            [value]="item.name"
            name="tematica"
            [(ngModel)]="params.tematica"
            (ngModelChange)="paramsChange$.next()"
          />
          <label class="form-check-label" for="thematic_{{ i }}">
            {{ item.name }} <small class="ms-2 text-accent">{{ item.count }}</small>
          </label>
        </div>
      </div>
      <div class="divider mb-1"></div>
      <div class="d-flex">
        <a class="btn btn-sm btn-link text-accent mx-auto" (click)="reset()">Reiniciar</a>
      </div>
    </form>
  </div>
</div>
